import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 设备API接口
// --------------------------------------------

/**
 * 获取设备列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDevice = async data => {
  return await $.get({
    url: 'admin/device/deviceList',
    data,
    isLoad: true,
  })
}

/**
 * 获取摄像头列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getCamera = async data => {
  return await $.get({
    url: 'admin/device/CameraList',
    data,
    isLoad: true,
  })
}

/**
 * 删除摄像头
 * @param {String} id 摄像头id
 */
export const delCamera = async id => {
  return await $.get({
    url: 'admin/device/CameraDel',
    data: { id },
  })
}

/**
 * 添加摄像头配置
 * @param {Object} data 详看接口文档
 */
export const addCamera = async data => {
  return await $.post({
    url: 'admin/device/CameraAdd',
    data,
  })
}

/**
 * 获取配置详情
 * @param {String} id 摄像头配置id
 */
export const getCameraDetail = async id => {
  return await $.get({
    url: 'admin/device/CameraEdit',
    data: { id },
  })
}

/**
 * 修改摄像头配置
 * @param {Object} data 详看接口文档
 */
export const updCamera = async data => {
  return await $.post({
    url: 'admin/device/CameraEdit',
    data,
  })
}

/**
 * 获取设备类型 类型
 * @param {String} type 设备类型
 */
export const getDSSelect = async type => {
  return await $.get({
    url: 'admin/device/UnitCamera',
    data: { unit_type: type },
  })
}

/**
 * 添加设备
 * @param {Object} data 详看接口文档
 */
export const addDevice = async data => {
  return await $.post({
    url: 'admin/device/UnitAdd',
    data,
  })
}

/**
 * 获取设备详情
 * @param {String} id 设备id
 */
export const getDeviceDetail = async id => {
  return await $.get({
    url: 'admin/device/UnitEdit',
    data: { id },
  })
}

/**
 * 修改详情
 * @param {Object} data 详看接口文档
 */
export const updDevice = async data => {
  return await $.post({
    url: 'admin/device/UnitEdit',
    data,
  })
}

/**
 * 删除设备
 * @param {String} id 设备id
 */
export const delDevice = async id => {
  return await $.get({
    url: 'admin/device/UnitDel',
    data: { id },
  })
}

/**
 * 切换设备状态
 * @param {Object} data 详看接口文档
 */
export const updDeviceStatus = async data => {
  return await $.post({
    url: 'admin/device/UnitStatus',
    data,
  })
}

/**
 * 获取单兵配置
 */
export const getIndConfig = async () => {
  return await $.get({
    url: 'admin/device/indiviDual',
    data: { id: 49 },
  })
}

/**
 * 获取手环配置
 */
export const getWristBConfig = async () => {
  return await $.get({
    url: 'admin/device/indiviDual',
    data: { id: 50 },
  })
}

/**
 * 获取气体配置
 */
export const getGASConfig = async () => {
  return await $.get({
    url: 'admin/device/indiviDual',
    data: { id: 75 },
  })
}

/**
 * 更改单兵、手环配置
 * @param {Object} data 详看接口文档
 */
export const updInWBConfig = async data => {
  return await $.post({
    url: 'admin/device/indiviDual',
    data,
  })
}

/**
 * 获取单兵坐标
 * @param {String} number 单兵设备号
 */
export const getIntLoca = async number => {
  return await $.get({
    url: 'admin/bind/singleMark',
    data: { unit_member: number },
  })
}

/**
 * 获取摄像头播放地址
 * @param {String} id 摄像头id
 */
export const getVideo = async id => {
  return await $.get({
    url: 'admin/bind/cameraVideo',
    data: { id },
  })
}

/**
 * 获取手环位置
 * @param {String} number 手环设备号
 */
export const getHandLoca = async number => {
  return await $.get({
    url: 'admin/bind/braceletMap',
    data: { unit_member: number },
  })
}

/**
 * 获取气体预警值
 * @param {String} id 气体id
 */
export const getGasWarn = async id => {
  return await $.get({
    url: 'admin/device/sngas',
    data: { unit_id: id },
  })
}

/**
 * 更新气体预警值
 * @param {Object} data 详看接口文档
 */
export const updGasWarn = async data => {
  return await $.post({
    url: 'admin/device/sngas',
    data,
  })
}

/**
 * 获取手环预警值
 * @param {String} id 气体id
 */
export const getHandWarn = async id => {
  return await $.get({
    url: 'admin/device/bracelet',
    data: { unit_id: id },
  })
}

/**
 * 更新手环预警值
 * @param {Object} data 详看接口文档
 */
export const updHandWarn = async data => {
  return await $.post({
    url: 'admin/device/bracelet',
    data,
  })
}

/**
 * 获取手环血压可视化
 * @param {Object} data 详看接口文档
 */
export const getHeartB = async data => {
  return await $.get({
    url: 'admin/bind/braceletHeart',
    data,
  })
}

/**
 * 获取气体可视化
 * @param {Obejct} data 详看接口文档
 */
export const getGas = async data => {
  return await $.get({
    url: 'admin/bind/toxicgasReal',
    data,
  })
}

/**
 * 获取体温可视化
 * @param {Object} data 详看接口文档
 */
export const getTem = async data => {
  return await $.get({
    url: 'admin/bind/braceletAnimal',
    data,
  })
}
